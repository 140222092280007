<template>
<div class="container-fluid px-0">
    <div class="row g-0">
        <div class="col-12" style="height: 48px">
            <button type="button" v-if="game?.props?.numPlayers == 2 && game?.props?.generation >= 6" 
              class="btn btn-lg next btn-danger" title="Desistir" @click="resign">
                <font-awesome-icon icon="flag" />
            </button>
            <a role="button" style="display: block; float:left" class="btn btn-lg next btn-warning" title="Abrir jogo em nova tab" :href="game.url" target="_blank">
                <font-awesome-icon icon="external-link-alt" />
            </a>
            <a role="button" style="display: block; float:left" class="btn btn-lg next btn-warning" title="Abrir jogo em spectator mode" :href="spectatorLink()" target="_blank">
                <font-awesome-icon icon="glasses" />
            </a>
            <button type="button" :class="nextGameClass" @click="goToNext" title="Ir para o proximo jogo">
                <font-awesome-icon icon="step-forward" />
            </button>
            <textarea id="Notas" rows="2" v-model="game.notes" placeholder="Escreve aqui as tuas notas" @input="saveNotes"></textarea>
            <button type="button" class="btn btn-primary btn-lg save" @click.self="toggleChat">
              <font-awesome-icon icon="comment" @click="toggleChat"/>
              <span v-if="game.read_chat == 0" class="position-absolute p-1 bg-danger rounded-circle"></span>
              <Chat :game="game._id" :openChat="chatVisible"></Chat>
            </button>
        </div>
        <div class="col-12" style="height: calc(100vh - 110px);">
          <iframe
            v-if="game"
            width="100%"
            height="100%"
            :src="game.url"
            frameborder="0"
          ></iframe>
          
        </div>
    </div>
</div>
</template>

<script>
import TMService from "../services/tm.service";
import util from '../util';
import Chat from './chat/Chat';

export default {
  name: "GameLoader",
  components: {Chat},
  data() {
    return {
      chatVisible: false,
      loading: true,
      myGames: [],
      gamesToPlay: [],
      gameIndex: 0,
      game: {},
      nextGameClass: {
        'btn': true, 
        'btn-lg': true,
        'next':true, 
        'btn-secondary':true,
        'btn-primary': false
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    TMService.getMyGames(this.currentUser.user_id).then(resp => {
        if(resp.data.length > 0) {
        
          this.myGames = resp.data.sort((a,b) => {
            let acmp = this.amActive(a) ? 1000000 : 0;
            let bcmp = this.amActive(b) ? 1000000 : 0;

            return (bcmp + b._id) - (acmp + a._id); 
            });

          let selectedGame = this.$route.params.gameId;

          if(selectedGame){
            let index = this.myGames.findIndex(x => x._id == selectedGame);
            let gameToChange = this.myGames[index];
            this.myGames.splice(index, 1);
            this.myGames.unshift(gameToChange);
          }
          this.game = this.myGames[0];
          this.gameIndex = 0;
          this.setNextButtonClass();
          this.loading = false;
        }
      });
  },
  methods: {
      saveNotes(){
        TMService.addNotes(this.game._id, this.currentUser.user_id, this.game.notes);
      },
      toggleChat(){
        this.chatVisible = !this.chatVisible;
      },
      goToNext(){
        this.gameIndex = (this.gameIndex + 1) % this.myGames.length;
        this.game = this.myGames[this.gameIndex];
        this.setNextButtonClass();
      },
      setNextButtonClass(){
        if(this.amActive(this.game)){
            this.nextGameClass['btn-secondary'] = false;
            this.nextGameClass['btn-primary'] = true;
          } else {
            this.nextGameClass['btn-secondary'] = true;
            this.nextGameClass['btn-primary'] = false;
          }
      },
      amActive(game){
        return !Array.isArray(game.props.activePlayer) || game.props.activePlayer.filter(x => x.colour == game.colour).length > 0;
      },
      goToGame(){
        window.open(this.game.url, '_blank');
      },
      spectatorLink(){
        return util.spectatorLink(this.game);
      },
      resign(){
        if (confirm("De certeza que queres desistir?")) {
          TMService.resign(this.game._id, this.currentUser.user_id).then( () => {
            this.$router.push('home');
          });          
        }
      }
  }
};
</script>

<style scoped>
    textarea {
        resize: vertical;
        display: -webkit-box;
        float: left;
        font-size: 0.9em;
        resize: none;
        border: none;
        width: calc(100vw - 360px);
        outline: none;
    }

    button {
        display: block;
        float: left;
    }

    .next {
        width: 60px;
    }

    .save {
        float: right;
        width: 60px;
    }

</style>